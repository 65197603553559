import request from '@/utils/request'

// 发送消息
export function sendMsg( data ) {
  return request( {
    url : '/ai/agent/sendMessage',
    method : 'post',
    data : data
  } )
}

// 创建会话id
export function createConversationId() {
  return request( {
    url : '/ai/agent/createConversationId',
    method : 'post'
  } )
}
